.matchup-container {
    flex-shrink: 0;
    width: 190px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    margin-top:10px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
  }

  
  .matchup-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-logo {
    width: 30px;
    height: 30px;
  }
  
  .team-name {
    font-size: 11px;
    font-weight: bold;
    margin-right: 6px;
    font-family: var(--font-main); /* Using --font-main */
  }
  
  .vs {
    font-weight: bold;
    font-size: 12px;
    margin-left:-5px;
    color: #000;
    font-family: var(--font-main); /* Using --font-main */
  }
  
  .pick-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pick-stats span {
    font-size: 12px;
    padding: 4px 5px;
    text-align: center;
    font-weight: 550;
    margin-bottom: 14px;
    font-family: var(--font-main); /* Using --font-main */
  }
  
  .top-picks {
    background-color: var(--main-color);
    color: #fff;
  }
  
  .all-picks {
    background-color:  var(--main-color);
    color: #fff;
  }
  
  .matchup-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
  }
  
  .matchup-datetime,
  .home-team {
    font-size: 12px;
    color: #666;
    font-family: var(--font-main); /* Using --font-main */
  }
  
  .matchup-datetime {
    margin-left: 4px;
    margin-bottom: -5px;
  }
  
  .home-team {
    margin-right: 4px;
  }
  