.ticket-analyzer-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 70px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket-analyzer-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Header styling */
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header h1 {
  font-family: var(--font-main);
  color: var(--dark-text-color);
  font-size: 28px;
  margin: 0;
}

.bankroll-display {
  font-family: var(--font-main);
  font-size: 16px;
  color: var(--dark-text-color);
  display: flex;
  align-items: center;
}

.bankroll-input {
  width: 80px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid var(--light-text-color);
  border-radius: 5px;
  text-align: right;
  margin-left: 8px;
}

/* Main content layout */
.main-content {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

/* Panel sizes for desktop */
.selected-players-container {
  flex: 1 1 600px;
  max-width: 700px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
  margin-bottom: 10px;
}

.parlay-builder-container {
  flex: 1 1 600px;
  max-width: 670px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
  margin-bottom: 10px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .ticket-analyzer-layout {
    gap: 10px;
  }
  .main-content {
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
  .selected-players-container,
  .parlay-builder-container {
    max-width: 100%;
    margin-right: 10px;
    flex: 1 1 300px;
  }
}
