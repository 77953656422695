.parlay-builder-container {
  padding: 20px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid var(--light-text-color);
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: var(--font-main);
}

.parlay-builder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.header-left {
  display: flex;
  flex-direction: column;
}

.header-left h3 {
  font-family: var(--font-main);
  color: var(--dark-text-color);
  font-size: 24px;
  margin: 0;
}

.bankroll-display {
  margin-top: 5px;
  font-size: 16px;
  color: var(--dark-text-color);
}

.bankroll-input {
  width: 80px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid var(--light-text-color);
  border-radius: 5px;
  text-align: right;
  margin-left: 8px;
}

.parlay-builder-top-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.parlay-builder-refresh-btn {
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--main-color);
}

.parlay-builder-suggest-players {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-roboto);
}

.parlay-builder-legs {
  margin-top: 10px;
  font-size: 1em;
  font-weight: normal;
  color: var(--medium-text-color);
}

.parlay-builder-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.parlay-builder-table th,
.parlay-builder-table td {
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  border-bottom: 1px solid var(--light-text-color);
  font-size: 16px;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}

.parlay-builder-table th {
  color: var(--dark-text-color);
  font-weight: bold;
}

.parlay-builder-remove-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main-color);
  font-size: 1.2em;
  padding: 5px;
}

/* Bottom summary styling */
.parlay-builder-summary {
  border: 1px solid var(--light-text-color);
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #fafafa;
  display: inline-block;
  text-align: left;
  margin-bottom: 15px;
  max-width: 300px;
}

.parlay-builder-overall-bet-input {
  width: 60px;
  padding: 5px;
  font-size: 13px;
  border: 1px solid var(--light-text-color);
  border-radius: 5px;
  text-align: right;
  margin-left: 5px;
}

.parlay-builder-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.parlay-builder-confirm-btn {
  background-color: var(--main-color);
  color: white;
  border: none;
  width: 110px;
  height: 52px;
  padding: 1px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-roboto);
}

/* Mobile adjustments for iPhone dimensions */
@media (max-width: 480px) {
  .parlay-builder-container {
    padding: 10px;
    max-height: 500px;
  }
  .header-left h3 {
    font-size: 20px;
  }
  .bankroll-display {
    font-size: 14px;
  }
  .bankroll-input {
    width: 70px;
    padding: 3px;
    font-size: 12px;
  }
  .parlay-builder-top-buttons {
    gap: 5px;
  }
  .parlay-builder-refresh-btn {
    font-size: 1.3em;
  }
  .parlay-builder-legs {
    font-size: 0.9em;
  }
  .parlay-builder-table th,
  .parlay-builder-table td {
    font-size: 14px;
    padding: 5px;
  }
  .parlay-builder-overall-bet-input {
    width: 50px;
    padding: 3px;
    font-size: 12px;
  }
  .parlay-builder-summary {
    max-width: 260px;
    padding: 10px;
    font-size: 12px;
  }
  .parlay-builder-confirm-btn {
    width: 100px;
    height: 45px;
    font-size: 14px;
  }
}
