.available-players-buttons {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  background-color: transparent;
  padding: 10px;
}

.available-players-matchup-btn {
  background-color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-main);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  flex-direction: row;
}

.available-players-matchup-btn img {
  width: 42px;
  height: 42px;
}

.default-btn {
  background-color: #ffffff;
  color: black;
  font-weight: bold;
}

.available-players-matchup-btn img:first-of-type {
  margin-right: 5px;
}

.available-players-matchup-btn img:last-of-type {
  margin-left: 5px;
}

.matchup-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-main);
  font-size: 12px;
  color: #333;
}

.matchup-at {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  font-family: var(--font-main);
}

.matchup-time span:last-child {
  font-size: 0.85em;
  margin-top: 3px;
  color: #666;
  font-family: var(--font-main);
}

/* Mobile adjustments for iPhone dimensions */
@media (max-width: 480px) {
  .available-players-buttons {
    gap: 10px;
    padding: 5px;
  }
  .available-players-matchup-btn {
    padding: 8px 10px;
  }
  .available-players-matchup-btn img {
    width: 32px;
    height: 32px;
  }
  .matchup-time {
    font-size: 10px;
  }
  .matchup-at {
    font-size: 12px;
  }
  .matchup-time span:last-child {
    font-size: 0.75em;
  }
}
